import React, { useState } from 'react';
import styles from './FilterControls.module.css'

const FilterControls = ({ onFilterChange }) => {
  const [sortOrder, setSortOrder] = useState('default');
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleFilterChange = () => {
    onFilterChange({ sortOrder, searchTerm, itemsPerPage });
  };

  return (
    <section>
      <div className={styles.displayCard}>
        <label htmlFor="sortOrder">Ordenar por</label>
        <select id="sortOrder"
          value={sortOrder}
          onChange={(e) => {
            setSortOrder(e.target.value);
            handleFilterChange();
          }}>
          <option value="default">Padrão</option>
          <option value="price-desc">Preço: Barato para Caro</option>
          <option value="price-asc">Preço: Caro para Barato</option>
          <option value="name-desc">Nome: de A a Z</option>
          <option value="name-asc">Nome: de Z a A</option>
        </select>
      </div>

      <div className={styles.displayCard}>
        <label htmlFor="searchTerm">Procurar por</label>
        <input
          type="text"
          id="searchTerm"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleFilterChange();
          }}
          placeholder="Palavra-chave"
        />
      </div>

      <div className={styles.displayCard}>
        <label htmlFor="itemsPerPage">Itens por página: </label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(parseInt(e.target.value));
            handleFilterChange();
          }}
        >
          <option value={5}>5 produtos</option>
          <option value={10}>10 produtos</option>
          <option value={20}>20 produtos</option>
          <option value={50}>50 produtos</option>
        </select>
      </div>
    </section>
  );
};

export default FilterControls;
