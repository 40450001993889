import whatsapp_icon from './whatsapp.svg';
import styles from './Widget.module.css';

function Widget() {
    return (
        <a href="https://api.whatsapp.com/send/?phone=5544998228614" target="_blank" rel="noopener noreferrer">
            <button className={styles.widget}>
                <img className={styles.whatsapp_icon} src={whatsapp_icon} alt='Icon' />
            </button>
        </a>
    );
}

export default Widget;
