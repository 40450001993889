import React, { useEffect, useState } from 'react';
import styles from './ProductList.module.css';

const ProductList = ({ filters, categories }) => {
  const [products, setProducts] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado para verificar se o usuário está autenticado

  // Função para verificar a sessão do usuário
  const checkSession = async () => {
    try {
      const response = await fetch('http://localhost/amelie/src/Requests/check_session.php', {
        method: 'GET',
        credentials: 'include', // Enviar cookies junto com a requisição
      });
      const data = await response.json();
      setIsAuthenticated(data.isLoggedIn); // Supomos que `isLoggedIn` vem do backend
    } catch (error) {
      console.error('Erro ao verificar a sessão:', error);
      setIsAuthenticated(false);
    }
  };

  // Função para buscar os produtos do backend
  const fetchProducts = async () => {
    try {
      const response = await fetch('http://localhost/amelie/src/Requests/fetch_products.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ filters, categories })
      });
      const data = await response.json();

      // Verifica se a resposta é um array antes de atualizar o estado
      if (Array.isArray(data)) {
        setProducts(data);
      } else {
        console.error('Response is not an array:', data);
        setProducts([]); // Fallback para array vazio
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]); // Fallback para array vazio
    }
  };

  // Função para adicionar o produto ao carrinho
  const handleAddToCart = (productId) => {
    if (isAuthenticated) {
      // Lógica para adicionar ao carrinho (fazer requisição ao backend)
      console.log('Produto adicionado ao carrinho:', productId);
    } else {
      // Redirecionar para login se o usuário não estiver autenticado
      window.location.href = '/login'; // Redireciona para a página de login
    }
  };

  // useEffect para buscar produtos e verificar a sessão ao carregar o componente
  useEffect(() => {
    checkSession(); // Verifica se o usuário está autenticado
    fetchProducts(); // Busca os produtos
  }, [filters, categories]);

  // Função para renderizar o card de produto
  const renderProductCard = (product) => (
    <div key={product.id} className={styles.productCard}>
      <h3 className={styles.productTitle}>{product.title}</h3>
      <img
        className={styles.productImage}
        src={product.main_image}
        alt={product.title}
        onError={(e) => { e.target.src = 'https://placehold.co/600x600?text=Produto'; }}
      />
      <p className={styles.productDescription}>{product.description}</p>
      <p className={styles.productPrice}>Preço R$: {product.base_price}</p>
      <div className={styles.quantityContent}>
        <label>Quantidade:</label>
        <input
          type='number'
          min='1'
          max='10000'
        />
      </div>
      <button
        className={styles.finishButton}
        onClick={() => handleAddToCart(product.id)}
      >
        Adicionar ao carrinho
      </button>
    </div>
  );

  return (
    <div className={styles.productsList}>
      {products.map(renderProductCard)}
    </div>
  );
};

export default ProductList;
