import React, { useState } from 'react';
import Slide from './Slides/Slide';
import FilterControls from './FilterControls/FilterControls';
import ProductList from './ProductList/ProductList';
import Informations from './Informations/Informations'

const Home = () => {
  const [filters, setFilters] = useState({});
  const [categories, setCategories] = useState([]);

  // Função para atualizar filtros
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  return (
    <>
      <Slide />

      <FilterControls onFilterChange={handleFilterChange} />

      <ProductList filters={filters} categories={categories} />

      <Informations />
    </>
  );
};

export default Home;
