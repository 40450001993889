import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Modal.module.css';
import logo from '../../assets/img/logo.png';
import close_icon from '../../assets/img/close.png';

const SignupModal = ({ user }) => {
  const navigate = useNavigate();

  // Redireciona se o usuário estiver logado
  useEffect(() => {
    if (user) {
      navigate('/'); // Redirecionar para a página inicial ou outra rota desejada
    }
  }, [user, navigate]);

  // Estado para armazenar os valores do formulário
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  // Estado para armazenar mensagens de erro
  const [error, setError] = useState('');

  // Função para fechar o modal
  const closeModal = () => {
    navigate(-1);
  };

  // Função para atualizar os valores do formulário em tempo real
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validações do lado do cliente
    if (!formData.name || !formData.email || !formData.password || !formData.confirmPassword) {
      setError('Todos os campos são obrigatórios.');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    // Dados a serem enviados para o backend
    const userData = {
      name: formData.name,
      email: formData.email,
      password: formData.password
    };

    try {
      // Enviar dados para o servidor usando fetch
      const response = await fetch('http://localhost/amelie/src/Requests/signup.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const result = await response.json();

      if (result.success) {
        alert(result.message);
        setError('');
        closeModal();
      } else {
        setError(result.message);
      }
    } catch (error) {
      console.error('Erro ao cadastrar:', error);
      setError('Erro ao conectar com o servidor. Por favor, tente novamente mais tarde.');
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={closeModal}>
          <img className={styles.close_icon} src={close_icon} alt="Fechar" />
        </button>

        {error && <p className={styles.errorMessage}>{error}</p>}
        <img className={styles.logo} src={logo} alt="Logo" />
        <h2>Cadastre-se</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Nome"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <input
            type="email"
            id="email"
            name="email"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <input
            type="password"
            id="password"
            name="password"
            placeholder="Senha"
            value={formData.password}
            onChange={handleChange}
            required
          />

          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirmar Senha"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />

          <button type="submit">Finalizar Cadastro</button>
        </form>
      </div>
    </div>
  );
};

export default SignupModal;
