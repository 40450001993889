import styles from './Informations.module.css'
import pixImage from './Icons/pix-svgrepo-com.svg'
import airplaneImage from './Icons/airplane-svgrepo-com.svg'
import checkImage from './Icons/check-circle-svgrepo-com.svg'
import cardImage from './Icons/credit-card-svgrepo-com.svg'

const Infromations = () => {
    return (
        <section className={styles.informations}>
            <div className={styles.card}>
                <img src={pixImage} alt="Icon" />
                <h1>Desconto</h1>
                <p>10% OFF no PIX</p>
            </div>
            <div className={styles.card}>
                <img src={cardImage} alt="Icon" />
                <h1>Parcelamento</h1>
                <p>12x sem juros</p>
            </div>
            <div className={styles.card}>
                <img src={checkImage} alt="Icon" />
                <h1>Garantia</h1>
                <p>De até 1 ano</p>
            </div>
            <div className={styles.card}>
                <img src={airplaneImage} alt="Icon" />
                <h1>Frete Grátis</h1>
                <p>Acima de R$ 500,00</p>
            </div>
        </ section>
    )
}

export default Infromations