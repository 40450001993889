import React, { useState, useEffect } from 'react';
import styles from './Slide.module.css'
import slide0 from './img/slide0.jpeg'
import slide1 from './img/slide1.jpeg'

const slides = [
    slide0,
    slide1,
];

const Slide = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Troca de slides automaticamente a cada 5 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.slide_container} style={{ position: 'relative', overflow: 'hidden' }}>
            <div
                className={styles.slides}
                style={{
                    display: 'flex',
                    transition: 'transform 0.5s ease-in-out',
                    transform: `translateX(-${currentIndex * 100}%)`
                }}
            >
                {slides.map((slide, index) => (
                    <img
                        key={index}
                        src={slide}
                        alt={`Slide ${index}`}
                        style={{ width: '100%', flexShrink: '0' }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Slide;
