import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Main from './Components/Home/Home';
import DefaultContent from './Components/DefaultContent';
import LoginModal from './Components/Header/Modals/LoginModal';
import SignupModal from './Components/Header/Modals/SignupModal';
import Widget from './Components/Widget/Widget';
// import Loader from './Components/Loader/Loader';
import Footer from './Components/Footer/Footer';

function App() {
  const [user, setUser] = useState(null);

  // Função para verificar a sessão ao carregar a página
  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch('http://localhost/amelie/src/Requests/check_session.php', {
          method: 'GET',
          credentials: 'include', // Inclui cookies na requisição
        });
        const result = await response.json();

        if (result.success) {
          setUser(result.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Erro ao verificar a sessão:', error);
        setUser(null);
      }
    };

    checkSession();
  }, []);

  return (
    <Router>
      <div className="App">
        {/* <Loader loading={true} /> */}
        <>
          <Routes>
            <Route path="/" element={<DefaultContent />} />
            <Route path="/login" element={<LoginModal setUser={setUser} user={user} />} />
            <Route path="/signup" element={<SignupModal user={user} />} />
            <Route path="/dashboard" element={<DefaultContent />} />
          </Routes>

          <Header user={user} setUser={setUser} />
          <Main />
          <Footer />

          <Widget />
        </>
      </div>
    </Router>
  );
}

export default App;
