import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import logo from '../assets/img/logo.png';

const Header = ({ user, setUser }) => {
  const navigate = useNavigate();

  // Função de logout
  const handleLogout = async () => {
    try {
      const response = await fetch('http://localhost/amelie/src/Requests/logout.php', {
        method: 'POST',
        credentials: 'include', // Incluir cookies na requisição
      });

      const result = await response.json();

      if (result.success) {
        setUser(null);
        navigate('/');
        window.location.reload();
      } else {
        console.error("Erro ao realizar logout:", result.message);
      }
    } catch (error) {
      console.error("Erro na requisição de logout:", error);
    }
  };

  return (
    <header className={styles.header}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="Logo" />
      </Link>
      <nav>
        <ul>
          {user ? (
            <>
              <li><Link to="/profile">Perfil</Link></li>
              <li><button onClick={handleLogout}>Sair</button></li>
            </>
          ) : (
            <>
              <li><Link to="/login">Entrar</Link></li>
              <li><Link to="/signup">Cadastre-se</Link></li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
